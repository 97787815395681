import React from "react";

import { Typography } from "antd";
import VegetationSimulation from "./Projects/Vegetation/VegetationSimulation";

const Home = () => {
  return <div>{/* <VegetationSimulation /> */}</div>;
};

export default Home;
