import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Link,
  Route,
  RouterProvider,
} from "react-router-dom";
import logo from "./logo.svg";
import "./App.css";
import Home from "./Pages/Home";
import { Layout, Menu, Button, theme } from "antd";

const { Header, Footer, Sider, Content } = Layout;

// const menuItems = [
//   {
//     label: <Link to='/'>Home</Link>,
//     key: "home",
//   },
//   {
//     label: <Link to='/projects'>Projects</Link>,
//     key: "projects",
//   },
// ];

function App() {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const router = createBrowserRouter(
    createRoutesFromElements(<Route path='/' element={<Home />} />)
  );

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header
        style={{
          background: colorBgContainer,
          width: "100%",
          boxShadow: "0px 2px 3px 0px rgba(10,10,10,0.64)",
          zIndex: 10,
        }}
      >
        <Layout>
          <Menu theme='light' mode='horizontal' />
        </Layout>
      </Header>
      <Layout style={{ backgroundColor: "#aadbc1" }}>
        <Content
          style={{
            margin: "24px 16px",
            padding: 24,
            height: "100%",
          }}
        >
          <RouterProvider router={router} />
        </Content>
      </Layout>
    </Layout>
  );
}

export default App;
